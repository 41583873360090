

/*login form class applies css to both login and sign up cards*/
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Roboto&family=Vampiro+One&display=swap');


#loginform{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:#1f2833;

  width: 30rem;
  height: 100%;
  margin: 100px auto;
  border-radius: 2px;
  padding: 1.6rem;
  margin-top: 1rem;
  text-align: center;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 2rem;
  box-shadow: 0 35px 46px rgba(0,0,0,0.30), 0 18px 25px rgba(0,0,0,0.22);
  
  
}



#loginform:hover {
  box-shadow: 0 8px 18px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transition: opacity 0.85s ease-in-out;
}

/*label styles each entry section*/

label{
  flex-wrap: wrap;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
  border: none;
  text-align:center;
  font-size: 1.8rem;
  transition: box-shadow 0.6s ease-in;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  opacity: .9;
}
/*input styles the specific box whene user types input*/

input:focus{
   box-shadow: inset 0px -3px 0px 0px #45a29e;
   outline: none;
   margin-right: 1rem;
  
}


 input{
  width: 92%;

  font-size: 1.4rem;
  /* margin: .8rem; */
  margin-top: 1.4rem;
  margin-right: 1.4;
  margin-left: 1.4;
  /* padding: 1.4rem; */
  color:#c5c6c7;
  font-family: 'Roboto', sans-serif;
  transition: box-shadow 0.2s ease-in;
  border: none;
 background: transparent;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
}

/*h1 applies to the header "Log In" and "Sign up"*/
h1{
  /* text-shadow: 1px .5px .5px lightgray, 0 0 4px white; */
  font-family: 'Orbitron', sans-serif;
  color: #66fcf1;
  
  
}


/*applies to buttons on both forms*/

button{
  border-radius: 2px;
  border: none;
  align-self: center;
  width: 80%;
  height: 40px;
  font-size: 1rem;
  margin-top: 2.4rem;
  color: white;
  font-weight: 700;

  background: linear-gradient(90deg,  #66fcf1 0%,  #45a29e  100%);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.65s ease-in;
  font-family: 'Orbitron', sans-serif;
 
}
button:disabled {
 opacity: 0.2;
 cursor: not-allowed;

}

button:hover{
  opacity: 0.6;
  background: linear-gradient(90deg,  darkcyan 0%,  midnightblue  100%);
}

h1{
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 2.3rem;

  
}

p{
  margin-bottom: 1rem;
}
#form-errors {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  margin-top: .5;
  

}

#login-link {
  padding: 1rem;
  color: #45a29e;
}
#login-link:hover{
  color: red;
}
/*styling for pirate insult api*/
article {
  margin-top: 4rem;
  color: #66fcf1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-wrap: wrap;
  /* align-items: center; */
  font-family: 'Vampiro One', cursive;
 
  font-size: 1.8rem;
  text-align: center;
  font-family: 'Vampiro One', cursive;
}

#insult {
  margin-top: 1rem;
 
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  font-size: 1.1rem;
  /* align-self: center; */
}
/*added max-width and margin*/
#loginform {
  max-width: 1080px; 
    margin: 0 auto;
}

#login{
  margin-top: 4.4rem;

}


#signup{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgba(255,255,255,.1); */
  /* background: linear-gradient(90deg, #fafafa 20%,    whitesmoke 100%); */
  background-color:#1f2833;
  width: 30rem;
  height: 100%;
  margin: 100px auto;
  border-radius: 2px;
  padding: 2rem;
  margin-top: .5rem;
  text-align: center;
  color: white;
  font-family: 'Orbitron', sans-serif;
 
  box-shadow: 0 35px 46px rgba(0,0,0,0.30), 0 18px 25px rgba(0,0,0,0.22);
  transition: 0.3s;
  /* border: 2px solid #fff;  */
  
}


  @media screen and (max-width: 600px) {
    #signup{
      margin-top: 1rem;
      margin-bottom: 2.5rem;
      margin: 2rem;
      width: 20rem;
    }
    #loginform{
      width: 20rem;
    }

  }
