/* 
color and font info: 

font-family: 'Grenze Gotisch', cursive;
font-family: 'Nunito', sans-serif;
DarkCyan
#008B8B
Khaki
#F0E68C
LightCoral
#F08080
MidnightBlue
#191970

*/
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Roboto&display=swap');


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

body {
  font-size: 2rem;
  /* font-size: 0.9rem; */
  font-family: 'Orbitron', sans-serif;
  background-color: #0B0C10;
  color: white;
 
}

.App {
  width: 100%;
  /* color: #66fcf1; */
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  font-family: 'Orbitron', sans-serif;
}

.dashHeader,
.commentHeader {
  /* background-color: #45a29e; */
  width: 100%;
  text-align: center;
  color: #66fcf1;

  font-family: 'Orbitron', sans-serif;
  /* border-bottom: 10px solid midnightblue;
  border-top: 10px solid midnightblue; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.profileLink {
  text-align: right;
  margin: 1em;
  text-decoration: underline;
  
}


h5 {
  margin: .6rem;
  margin-bottom: 1.2rem;
  margin-top: 1rem;
  padding: 0em 10%;
  font-size: 1.1rem;
  width: 100%;
  line-height: 1;
  text-align: center;
  color: #45a29e;
  font-family: 'Orbitron', sans-serif;
}

h1 {
  text-align: center;
  color:white;
  font-size: 10rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 100%;

  font-family: 'Orbitron', sans-serif;
}

h3,
p {
  display: inline;
  color: white;
  /* margin: 5px; */
}

h4 {
  color: #66fcf1;
}
.card {
  background: #1f2833;
  width: 20em;
  color: white;
  margin: 1em;
  padding: 0.7em;
  font-size: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* border: 7px solid #45a29e; */
  line-height: 1.3;
  transition: ease-in-out 200ms;
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.19), 0 3px 4px rgba(0, 0, 0, 0.24);
}

.commentText {
  margin-bottom: 1em;
  
}

.cardButton {

  margin-top: 1em;
  font-weight: bold;
  font-size: 0.7em;
  text-transform: uppercase;
  color: white;
  /* background: lightcoral; */
  width: 7em;
  height: 3em;
  outline: none;
  /* border: 4px solid #66fcf1; */
  border-radius: 4px;
  transition: ease-in-out 175ms;
}

.cardTitles{
  font-size: 1.7rem;
  color: #66fcf1;
 
}
.cardButton:hover {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.19), 0 6px 8px rgba(0, 0, 0, 0.24);
}

.cardButton:active {
  background-color: #e42a2a;
  color: white;
}

.form-errors {
  font-size: 1.2rem;
  color: red;
}

.commentHeader > h1 {
  text-align: center;
  padding: 0.7em;
  margin-bottom: 0em;
}
.commentHeader {
  width: 100vw;

  margin-bottom: 2em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.commentContainer {
  width: 80%;
  margin: auto;
  font-size: 1.5rem;
  background: #1f2833;
  /* border: 10px solid #45a29e; */
 
  padding: 1em;
  padding-top: 2em;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

h2{
  font-size: 1.3rem;
  color: #c5c6c7;
}

.navbar{
  width:80vw;
  height: 10vh;
  background-color: #0b0c10;
  color: #66fcf1;
  display: flex;
  flex-direction: row;
 
  align-items: center;
  justify-content: space-around;
  font-size: 1rem;

}
.navbar a {
  text-decoration: none;
  color: white;
  margin: 0 30px;
  padding-top: .5rem;
}
.navbar a:hover {
  text-decoration: wavy;
  color: black;
}

.userComments {
  width: 60%;
  text-align: left;
  margin: 1rem 0;
  font-size: 2rem;
  line-height: 2;
}

#salt{
  color: red;
  font-size: 1.2rem;
}

#commentid{
  color: #66fcf1;
}

@media screen and (max-width: 850px){
  .commentHeader > h1{
    font-size:3rem;
  }
  .dashHeader > h1{
    font-size:3rem;
  }
 
}